<div class="container pu-upload d-flex flex-column">
    <div class="d-flex title-container pu-upload-files">
        <dx-button icon="arrowleft" stylingMode='text' (click)="close.emit(false);">
        </dx-button>
        <span class="fw-bold">{{srvOnBoarding.pageHeader}}</span>
    </div>
    <div class="pu-upload-container- flex-fill">
        <div class="d-flex flex-wrap upload-doc">
            <div *ngFor="let url of previewUrl">
                <div class="image-preview pu-mb-15 pu-mr-15" *ngIf="url">
                    <img [src]="url" class="w-100" />
                </div>
            </div>

            <div class="form-group add-image-btn">
                <input #uploadFile type="file" name="image" (change)="getUploadedFiles($event)" style="display: none;" />
                <dx-button *ngIf="fileData.length<1" icon="add" stylingMode='text' for="uploadFile" (click)="uploadFile.click()">
                </dx-button>
            </div>

            <div *ngIf="fileUploadProgress">
                Upload progress: {{ fileUploadProgress }}
            </div>
        </div>
    </div>
    <div class="d-flex align-items-end">
        <button class="btn btn-save w-100" (click)="onSave()">Save</button>
    </div>
</div>