import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import notify from 'devextreme/ui/notify';
import { PuOnboardingService } from 'src/app/pu-services/pu-onboarding.service';

@Component({
  selector: 'app-pu-update-utr',
  templateUrl: './pu-update-utr.component.html',
  styleUrls: ['./pu-update-utr.component.scss']
})
export class PuUpdateUtrComponent implements OnInit {
  
  strUtrValue: string;
  @Output() onScreenAction : EventEmitter<any> = new EventEmitter();

  constructor(
    public srvOnBoarding: PuOnboardingService) { }
  

  ngOnInit(): void {
    // this.srvOnBoarding.getUTRDetails().subscribe(resp => {
    //   console.log('UTR', resp);
    // });
  }

  onSave(event){
    if (!event.validationGroup.validate().isValid) {
      return;
    } else {  
      this.srvOnBoarding.setUTRDetails(this.strUtrValue).subscribe(respUTR => {
        if (respUTR) {
          notify({
            message: respUTR,
            type: 'success',
            displayTime: 1000,
            width: 350,
            position: {
              my: 'right bottom',
              at: 'right bottom',
              offset: "-20 -25"
            }
          });
        }  
        this.onScreenAction.emit(true);
        // this.router.navigate(['/onBoarding']);
      });
    }
  }

  backToList() {
    this.onScreenAction.emit(false);
  }

}
