import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FacebookLoginProvider, GoogleLoginProvider, SocialAuthService } from 'angularx-social-login';
import { pageMsg } from '../constants';
import { ILoginForm, LoginTypes } from '../pu-login/pu.model';
import { PuOnboardingService } from '../pu-services/pu-onboarding.service';
import { HttpClient } from '@angular/common/http';
import { AtlAuthenticationService } from '../common';

@Component({
  selector: 'app-pu-login-one',
  templateUrl: './pu-login-one.component.html',
  styleUrls: ['./pu-login-one.component.scss']
})
export class PuLoginOneComponent implements OnInit {
  title = 'Log in';
  radioGroup = ['Email', 'Postal Code'];
  radioValue = this.radioGroup[0];
  dynamicPlaceholder = 'Email';
  dynValue;
  dynValMsg = pageMsg.reqEmail;
  minMob = 10;
  maxMob = 13;
  formObj: ILoginForm = {
    // name: 'ASADSDSDSF',
    // mobile: '8952214442',
    // email: 'Info@accentra.co.uk',
    // postalCode: '9jiqt5x7'
  };
  isFormLogin = false;
  showErrMsg = false;

  strErrorMsg: string;

  constructor(
    private socialAuthServ: SocialAuthService,
    public puServ: PuOnboardingService,
    private router: Router,
    private httpClient: HttpClient,
    private atlAuthenticationService: AtlAuthenticationService
  ) { }

  ngOnInit(): void {

    if (this.atlAuthenticationService.isValid) {
      this.setLoggedInPage();
      this.puServ.loginInfo = this.atlAuthenticationService.currentUserValue;
    }

  }

  setLoggedInPage() {

    this.puServ.isLoggedIn = true;
    this.puServ.showOnBoarding = true;
    // this.router.navigate(['/onBoarding']);

  }

  btnClick(event, btnType) {
    switch (btnType) {
      case LoginTypes.Google:
        this.isFormLogin = false;
        this.socialAuthServ.signIn(GoogleLoginProvider.PROVIDER_ID).then(value => {
          console.log('GSignin', value);
          if (value) {
            this.puServ.loginInfo.name = value.name;
            this.puServ.loginInfo.email = value.email;
            this.puServ.loginInfo.imgProfile = value.photoUrl;
            // this.router.navigate(['/onBoarding']);

            // this.atlAuthenticationService.validateInternalLogin('test', 'test').subscribe(res => {

            // });
            // this.setLoggedInPage();

            this.atlAuthenticationService.validateExternalLogin(this.puServ.loginInfo, GoogleLoginProvider.PROVIDER_ID,
              value.idToken).subscribe(res => {
                this.setLoggedInPage();
              });
          }
        });
        break;
      case LoginTypes.FaceBook:
        this.isFormLogin = false;
        this.socialAuthServ.signIn(FacebookLoginProvider.PROVIDER_ID).then(value => {
          console.log('FBSignin', value);
          if (value) {
            this.puServ.loginInfo.name = value.name;
            this.puServ.loginInfo.email = value.email;
            this.puServ.loginInfo.imgProfile = value.photoUrl;
            // this.router.navigate(['/onBoarding']);

            this.atlAuthenticationService.validateExternalLogin(this.puServ.loginInfo, FacebookLoginProvider.PROVIDER_ID,
              value.authToken).subscribe(res => {
                this.setLoggedInPage();
              });
          }
        });
        break;
      case LoginTypes.Form:
        this.isFormLogin = true;
        break;
      case LoginTypes.Back:
        this.isFormLogin = false;
        break;
      case LoginTypes.Others:
        if (!this.isFormValid()) {          
            this.strErrorMsg = '*Please provide any 3 inputs to proceed';
            this.showErrMsg = true;
            return;          
        } else {
          this.showErrMsg = false;
          this.puServ.loginInfo.name = this.formObj.name;
          this.puServ.loginInfo.email = this.formObj.email;
          this.puServ.loginInfo.imgProfile = this.formObj.imgProfile;

          const onBoardingObj = {
            Surname: this.formObj.name,
            Mobile: this.formObj.mobile,
            PostCode: this.formObj.postalCode,
            EmailID: this.formObj.email,
          };

          this.atlAuthenticationService.validateInternalLogin(this.puServ.loginInfo, onBoardingObj, 'onboarding').subscribe(res => {
            this.setLoggedInPage();
          }, error => {
            // this.showErrMsg = true;
            // this.strErrorMsg = error.error.error;
            // this.strErrorMsg = 'Invalid details. Please provide valid details.'
          });
          // this.router.navigate(['/onBoarding']);
        }
        break;
    }
  }

  private isFormValid(){
    let count=0;
    for (const key in this.formObj) {
      if (this.formObj[key]) {
        count+=1;        
      }
    }
    return count>= 3;
  }
}
