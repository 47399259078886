<div class="container">
    <div class="row">
        <div class="col-sm-9 col-md-7 col-lg-5 m-auto">
            <div class="card card-signin my-5">
                <div class="card-body">

                    <dx-scroll-view>
                        <div class="dx-card">
                            <dx-validation-group>
                                <div class="login-header">
                                    <div class="login-company-logo mx-auto"><img src="https://app-dev.primotime.co.uk/Image/PTlogo-full.svg"/></div>
                                </div>
                                
                                <div class="dx-field">
                                    <dx-button class="pu-mr-10 login-btn" icon="fab fa-google" type="normal" text="Login With Google"
                                        (onClick)="btnClick($event, 'G')">
                                    </dx-button>
                                    <dx-button class="pu-ml-10 login-btn" icon="fab fa-facebook-f" type="normal" text="Login With Facebook"
                                        (onClick)="btnClick($event, 'F')">
                                    </dx-button>
                                </div>
                                
                                <div class="text-center text-through"><span>Or</span></div>

                                <div class="dx-field">
                                    <dx-text-box [(value)]="formObj.name" placeholder="Surname" width="100%">
                                        <dx-validator>
                                            <dxi-validation-rule type="required" message="Surname is required"></dxi-validation-rule>
                                        </dx-validator>
                                    </dx-text-box>
                                </div>
                                <div class="dx-field">
                                    <dx-text-box [(value)]="formObj.mobile" placeholder="Mobile" width="100%">
                                        <dx-validator>
                                            <dxi-validation-rule type="required" message="Mobile no is required"></dxi-validation-rule>
                                            <dxi-validation-rule type="range" [max]="minMob" [max]="maxMob" message="Enter Valid Mobile Number"></dxi-validation-rule>
                                        </dx-validator>
                                    </dx-text-box>
                                </div>                                
                                <div class="dx-field">
                                    <dx-text-box [(value)]="formObj.email" placeholder="Email" width="100%">
                                        <dx-validator>
                                            <!-- <dxi-validation-rule type="required" [message]="dynValMsg"></dxi-validation-rule>
                                            <dxi-validation-rule *ngIf="dynValue === 'Email'" type="email" message="Email is invalid"></dxi-validation-rule> -->
                                        </dx-validator>
                                    </dx-text-box>
                                </div>
                                <div class="dx-field">
                                    <dx-text-box [(value)]="formObj.postalCode" placeholder="Postal Code" width="100%">
                                        <dx-validator>
                                            <!-- <dxi-validation-rule type="required" [message]="dynValMsg"></dxi-validation-rule>
                                            <dxi-validation-rule *ngIf="dynValue === 'Email'" type="email" message="Email is invalid"></dxi-validation-rule> -->
                                        </dx-validator>
                                    </dx-text-box>
                                </div>
                                <div class="dx-field" style="color: red;" *ngIf='showErrMsg'>
                                    *Please provide Email or Postal Code to proceed 
                                </div>
                                <div class="dx-field">
                                    <dx-button icon="fa fa-sticky-note" type="default" text="Log In with Form" (onClick)="btnClick($event, 'O')" width="100%">
                                    </dx-button>
                                </div>                                
                            </dx-validation-group>
                        </div>
                    </dx-scroll-view>
                </div>
            </div>
        </div>
    </div>
</div>