
import {
    HttpEvent,
    HttpHandler,
    HttpInterceptor,
    HttpRequest
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of, throwError as observableThrowError } from 'rxjs';
import { catchError, finalize, map } from 'rxjs/operators';
import { PuOnboardingService } from 'src/app/pu-services/pu-onboarding.service';
import { SessionKey } from '../models/session-key';
import { AtlAuthenticationService } from '../services/atl-authentication.service';
import notify from 'devextreme/ui/notify';
  
@Injectable({ providedIn: 'root' })
export class HTTPStatus {
    private requestInFlight$: BehaviorSubject<boolean>;
    constructor() {
        this.requestInFlight$ = new BehaviorSubject(false);
    }

    setHttpStatus(inFlight: boolean) {
        this.requestInFlight$.next(inFlight);
    }

    getHttpStatus(): Observable<boolean> {
        return this.requestInFlight$.asObservable();
    }
}

@Injectable()
export class HTTPListener implements HttpInterceptor {
    public pendingRequests: number = 0;

    constructor(
        private status: HTTPStatus,
        private authenticationService: AtlAuthenticationService,
        private srvOnBoarding: PuOnboardingService) { }

    intercept(
        req: HttpRequest<any>,
        next: HttpHandler
    ): Observable<HttpEvent<any>> {
        

        const currentAuth = this.authenticationService.currentAuthValue;
        const sessionKey: SessionKey = this.authenticationService.currentSessionKeyValue;
        if(!sessionKey || !sessionKey.CompanyID){
            this.srvOnBoarding.isPopupVisible = true;
            this.srvOnBoarding.popUpContent = 'Contractor Details not found.';
            return of(null);
        }
        this.pendingRequests++;
        this.status.setHttpStatus(true);

        if (currentAuth && currentAuth.access_token) {
            req = req.clone({
                setHeaders: {
                    Authorization: `Bearer ${currentAuth.access_token}`,
                    CompanyID: sessionKey.CompanyID.toString(),
                    ContractorID: sessionKey.ContractorID.toString(),
                    ProspectID: sessionKey.ProspectID.toString(),
                }
            });
        }

        return next.handle(req).pipe(
            map((event: any) => {
                // if(event.status === 200){
                //     notify({
                //         message: event.statusText, 
                //         type: 'success', 
                //         displayTime: 500,
                //         width: 350,
                //         position: {
                //             my: 'right bottom',
                //             at: 'right bottom',
                //             offset:"-20 -25"
                //         }
                //     });
                // }                
                return event;
            }),
            catchError(err => {
                console.log('err', err);
                if (err.status === 404 || err.status === 400 || err.status === 500) {
                    // Throw error pop up
                    this.srvOnBoarding.isPopupVisible = true;
                    if(err && err.error && err.error.error){
                        this.srvOnBoarding.popUpContent = err.error.error;
                    } else if(err && err.error){
                        this.srvOnBoarding.popUpContent = err.error;
                    }
                }
                if (err.status === 401 || err.status === 504) {
                    // auto logout if 401 response returned from api
                    this.authenticationService.logout();
                    location.href = this.authenticationService.params;
                }

                // const error = err.error.message || err.error.error_description || err.statusText;
                // return observableThrowError(error);
                return observableThrowError(err);
            }),
            finalize(() => {
                this.pendingRequests--;
                if (this.pendingRequests <= 0) {
                    this.status.setHttpStatus(false);
                }
            })
        );

    }

}
