import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { PuLoginOneComponent } from './pu-login-one/pu-login-one.component';
import { PuOnboardingComponent } from './pu-onboarding/pu-onboarding/pu-onboarding.component';
import { PuUpdateBankComponent } from './pu-onboarding/pu-update-bank/pu-update-bank.component';
import { PuUpdateP45Component } from './pu-onboarding/pu-update-p45/pu-update-p45.component';
import { PuUpdateUtrComponent } from './pu-onboarding/pu-update-utr/pu-update-utr.component';
import { PUUploadFilesComponent } from './pu-upload-files/pu-upload-files.component';

export enum routeUrls {
  Login = 'login',
  OnBoarding = 'onBoarding',
  UploadDoc = 'uploadDoc',
  UpdateUTR = 'updateUTR',
  UpdateP45 = 'updateP45',
  UpdateBank = 'updateBank',
}

const routes: Routes = [
  // {
  //   path: '',
  //   redirectTo: '/?id=MTc2NzZ8Mg==',
  //   pathMatch: 'full'
  // }
  // {
  //   path: '**',
  //   redirectTo: 'login',
  //   // canActivate: []
  // },{
  //   path: 'login',
  //   component: PuLoginOneComponent,
  //   // canActivate: []
  // },
  // {
  //   path: 'onBoarding',
  //   component: PuOnboardingComponent,
  //   // canActivate: []
  // },
  // {
  //   path: 'uploadDoc',
  //   component: PUUploadFilesComponent,
  //   // canActivate: []
  // },
  // {
  //   path: 'updateUTR',
  //   component: PuUpdateUtrComponent,
  //   // canActivate: []
  // },
  // {
  //   path: 'updateP45',
  //   component: PuUpdateP45Component,
  //   // canActivate: []
  // },
  // {
  //   path: 'updateBank',
  //   component: PuUpdateBankComponent,
  //   // canActivate: []
  // }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
