import { CommonModule } from '@angular/common';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { FacebookLoginProvider, GoogleLoginProvider, SocialAuthServiceConfig, SocialLoginModule } from 'angularx-social-login';
import { DxLoadPanelModule } from 'devextreme-angular';
import { NgCircleProgressModule } from 'ng-circle-progress';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HTTPListener } from './common/core/httpinterceptor.service';
import { pid } from './constants';
import { DXCompModules } from './dx-imports.models';
import { PuLoginOneComponent } from './pu-login-one/pu-login-one.component';
import { PuLoginTwoComponent } from './pu-login-two/pu-login-two.component';
import { PuLoginComponent } from './pu-login/pu-login.component';
import { PuOnboardingComponent } from './pu-onboarding/pu-onboarding/pu-onboarding.component';
import { PuUpdateBankComponent } from './pu-onboarding/pu-update-bank/pu-update-bank.component';
import { PuUpdateP45Component } from './pu-onboarding/pu-update-p45/pu-update-p45.component';
import { PuUpdateUtrComponent } from './pu-onboarding/pu-update-utr/pu-update-utr.component';
import { PUUploadFilesComponent } from './pu-upload-files/pu-upload-files.component';
import { PuPopUpComponent } from './common/components/pu-pop-up/pu-pop-up.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {MatStepperModule} from '@angular/material/stepper';
import { ProgressBarModule } from 'angular-progress-bar';

@NgModule({
  declarations: [
    AppComponent,
    PuLoginComponent,
    PuOnboardingComponent,
    PUUploadFilesComponent,
    PuLoginOneComponent,
    PuLoginTwoComponent,
    PuUpdateUtrComponent,
    PuUpdateP45Component,
    PuUpdateBankComponent,
    PuPopUpComponent,
  ],
  imports: [
    CommonModule,
    BrowserModule,
    FormsModule,
    AppRoutingModule,
    DXCompModules,
    SocialLoginModule,
    NgbModule,
    NgCircleProgressModule.forRoot(),
    HttpClientModule,
    DxLoadPanelModule,
    BrowserAnimationsModule,
    MatStepperModule,
    ProgressBarModule
  ],
  providers: [
    {
      provide: 'SocialAuthServiceConfig',
      useValue: {
        autoLogin: false,
        providers: [
          {
            id: GoogleLoginProvider.PROVIDER_ID,
            provider: new GoogleLoginProvider(pid.Google)
          },
          {
            id: FacebookLoginProvider.PROVIDER_ID,
            provider: new FacebookLoginProvider(pid.Facebook)
          }
        ]
      } as SocialAuthServiceConfig,
    },
    { provide: HTTP_INTERCEPTORS, useClass: HTTPListener, multi: true },
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
