<div class="container pu-upload d-flex flex-column">
    <div class="d-flex title-container pu-upload-files">
        <dx-button icon="arrowleft" stylingMode='text' (click)="backToList();">
        </dx-button>
        <span class="fw-bold">{{srvOnBoarding.pageHeader}}</span>
    </div>
    <dx-validation-group>
        <div class="pu-mxy-15 pu-upload-doc flex-fill">
    
            <div class="dx-field">
                <dx-text-box [(value)]="strUtrValue" placeholder="Enter UTR" width="100%" [maxLength]="12">
                    <dx-validator>
                        <dxi-validation-rule type="required" message="UTR is required"></dxi-validation-rule>
                    </dx-validator>
                </dx-text-box>
            </div>
    
        </div>
        <div class="d-flex align-items-end dx-field">
            <dx-button class="btn btn-save w-100" (onClick)="onSave($event)">Save</dx-button>
        </div>
    </dx-validation-group>
</div>