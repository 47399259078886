<div class="container pu-upload d-flex flex-column">
    <div class="d-flex title-container pu-upload-files">
        <dx-button icon="arrowleft" stylingMode='text' (click)="backToList();">
        </dx-button>
        <span class="fw-bold">{{srvOnBoarding.pageHeader}}</span>
    </div>
    <div class="pu-mxy-15 pu-upload-doc flex-fill">
        <dx-validation-group>
            <div class="dx-field">
                <dx-select-box valueExpr="value" displayExpr="text" placeholder="Starter Declaration Type"
                    [showClearButton]="true" [dataSource]="lstDeclarationType" (onValueChanged)="valueChange($event, 'decType')" >
                    <dx-validator>
                        <dxi-validation-rule type="required" message="Starter Declaration Type is required">
                        </dxi-validation-rule>
                    </dx-validator>
                </dx-select-box>
            </div>
            <div class="dx-field">
                <dx-number-box placeholder="Gross for Tax" width="100%" format="#.00" value="0.00"
                    [showSpinButtons]="true" [showClearButton]="true" (onValueChanged)="valueChange($event, 'grossTax')">
                    <dx-validator>
                        <dxi-validation-rule type="required" message="Gross Tax Amount is required">
                        </dxi-validation-rule>
                    </dx-validator>
                </dx-number-box>
            </div>
            <div class="dx-field">
                <dx-number-box placeholder="Tax deducted" width="100%" format="#.00" value="0.00"
                    [showSpinButtons]="true" [showClearButton]="true" (onValueChanged)="valueChange($event, 'taxDeducted')">
                    <dx-validator>
                        <dxi-validation-rule type="required" message="Tax deduction amount is required">
                        </dxi-validation-rule>
                    </dx-validator>
                </dx-number-box>
            </div>
        </dx-validation-group>
    </div>
    <div class="d-flex align-items-end">
        <dx-button class="btn btn-save w-100" (onClick)="onSave($event)">Save</dx-button>
    </div>
</div>