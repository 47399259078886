<div class="container">
    <div class="row">
        <div class="col-sm-9 col-md-7 col-lg-5 m-auto">
            <div class="card card-signin my-5">
                <div class="card-body">

                    <dx-scroll-view>
                        <div class="dx-card">
                            <div class="login-header">
                                <div class="login-company-logo mx-auto"><img src="https://testserver.accentra.co.uk/Primo1-PP-Test-A/CSS/Images/PrimoUmbrellaLogo.svg"/></div>
                            </div>
                            <div *ngIf="!isFormLogin">
                                <div class="dx-field">
                                    <dx-button icon="fab fa-google" class="login-btn login-google" type="normal" text="Login With Google" (onClick)="btnClick($event, 'G')"
                                        width="100%">
                                    </dx-button>
                                </div>
                                <div class="dx-field">
                                    <dx-button icon="fab fa-facebook-f" class="login-btn login-fb" type="normal" text="Login With Facebook" (onClick)="btnClick($event, 'F')"
                                        width="100%">
                                    </dx-button>
                                </div>
                                <div class="dx-field">
                                    <dx-button icon="fa fa-sticky-note" class="login-btn login-form" type="normal" text="Login With Form" (onClick)="btnClick($event, 'M')" width="100%">
                                    </dx-button>
                                </div>
                            </div>
                            <dx-validation-group *ngIf="isFormLogin">
                                
                                <div class="dx-field">
                                    <dx-text-box [(value)]="formObj.name" placeholder="Surname" width="100%">
                                        <!-- <dx-validator>
                                            <dxi-validation-rule type="required" message="Surname is required"></dxi-validation-rule>
                                        </dx-validator> -->
                                    </dx-text-box>
                                </div>
                                <div class="dx-field">
                                    <dx-text-box [(value)]="formObj.mobile" placeholder="Mobile" width="100%">
                                        <!-- <dx-validator> -->
                                            <!-- <dxi-validation-rule type="required" message="Mobile no is required"></dxi-validation-rule> -->
                                            <!-- <dxi-validation-rule type="range" [min]="minMob" [max]="maxMob" message="Enter Valid Mobile Number"></dxi-validation-rule> -->
                                        <!-- </dx-validator> -->
                                    </dx-text-box>
                                </div>                               
                                <div class="dx-field">
                                    <dx-text-box [(value)]="formObj.email" placeholder="Email" width="100%">
                                        <!-- <dx-validator> -->
                                            <!-- <dxi-validation-rule type="custom" [validationCallback]="validateMailPostalCode" message="Email or Postal Code"></dxi-validation-rule> -->
                                            <!-- <dxi-validation-rule *ngIf="dynValue === 'Email'" type="email" message="Email is invalid"></dxi-validation-rule> -->
                                        <!-- </dx-validator> -->
                                    </dx-text-box>
                                </div>
                                <div class="dx-field">
                                    <dx-text-box [(value)]="formObj.postalCode" placeholder="Postal Code" width="100%">
                                        <!-- <dx-validator> -->
                                            <!-- <dxi-validation-rule type="required" [message]="dynValMsg"></dxi-validation-rule>
                                            <dxi-validation-rule *ngIf="dynValue === 'Email'" type="email" message="Email is invalid"></dxi-validation-rule> -->
                                        <!-- </dx-validator> -->
                                    </dx-text-box>
                                </div>
                                <div class="dx-field" style="color: red;" *ngIf='showErrMsg'>
                                    {{strErrorMsg}} 
                                </div>
                                <div class="dx-field">
                                    <dx-button type="default" text="Log In" (onClick)="btnClick($event, 'O')" width="100%">
                                    </dx-button>
                                </div>
                                <div class="dx-field">
                                    <dx-button type="default" text="Back" (onClick)="btnClick($event, 'B')" width="100%">
                                    </dx-button>
                                </div>
                            </dx-validation-group>
                        </div>
                    </dx-scroll-view>
                </div>
            </div>
        </div>
    </div>
</div>

<dx-popup
    class="dx-popup-class"
    [width]="300"
    [height]="200"
    title="ERROR"   
    showCloseButton='true'  
    [closeOnOutsideClick]="!puServ.isPopupVisible"
    [(visible)]="puServ.isPopupVisible"
    >
    <div *dxTemplate="let data of 'content'">
        <p [innerHTML]="puServ.popUpContent">
        </p>
    </div>
</dx-popup>