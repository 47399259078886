import { Component, OnInit } from '@angular/core';
import { PuOnboardingService } from 'src/app/pu-services/pu-onboarding.service';

@Component({
  selector: 'app-pu-pop-up',
  templateUrl: './pu-pop-up.component.html',
  styleUrls: ['./pu-pop-up.component.scss']
})
export class PuPopUpComponent implements OnInit {

  closeButtonOptions: {};
  constructor(
    public srvOnBoarding: PuOnboardingService,
  ) { 
    this.closeButtonOptions = {
      text: "OK",
      onClick: function(e) {
          srvOnBoarding.isPopupVisible = false;
      }
  };
  }

  ngOnInit(): void {
  }

  
}
