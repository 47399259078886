import { Component, OnInit, ViewChild } from '@angular/core';
import { AtlAuthenticationService } from 'src/app/common';
import { PuOnboardingService } from 'src/app/pu-services/pu-onboarding.service';

@Component({
  selector: 'app-pu-onboarding',
  templateUrl: './pu-onboarding.component.html',
  styleUrls: ['./pu-onboarding.component.scss']
})
export class PuOnboardingComponent implements OnInit {

  compPercentage = 0;
  onBoardingLevels = [];
  showUploadForm = false;
  formHeaderText = '';
  menuItems = [];
  showProfile = false;
  formUrl = '';
  formType = '';
  isPopupVisible = true;
  showVerificationDocType = false;
  verificationDocTypes = [];
  showConfirmation: boolean;
  signContract = false;

  constructor(
    public srvOnBoarding: PuOnboardingService,
    private atlAuthenticationService: AtlAuthenticationService
  ) {
    this.getBoardingStages();
  }

  private getBoardingStages() {
    this.srvOnBoarding.getOnBoardingLevels(null).subscribe((respOnBoard: any) => {
      if(respOnBoard){
        this.compPercentage = respOnBoard.Percentage;
        this.onBoardingLevels = respOnBoard.OnboardingStatusDto ?
          respOnBoard.OnboardingStatusDto.filter(stage => Number(stage.IsObsolete) === 0) : [];
        this.srvOnBoarding.showOnBoarding = true;
        this.onBoardingLevels.map(item => item.disabled = item.IsEnabled === '0');      
      }
    });
  }

  ngOnInit(): void {
  }

  onRowItemClick(item) {
    if (!Number(item.IsEnabled)) {
      return false;
    }
    if (!item.ScreenCode) {
      this.srvOnBoarding.isPopupVisible = true;
      return false;
    }
    this.formType = item.ScreenCode;
    this.formUrl = item.FormURL;
    this.srvOnBoarding.pageHeader = item.FormHeaderText;
    this.srvOnBoarding.showOnBoarding = false;
    this.srvOnBoarding.curStageId = item.ID;

    switch (item.ScreenCode) {
      case 'JUMI':

        this.srvOnBoarding.showOnBoarding = true;
        this.srvOnBoarding.getVerificationIDTypes().subscribe(res => {
          this.verificationDocTypes = res;
          this.srvOnBoarding.verificationIDTypes = res;
          this.showVerificationDocType = true;
        });

        break;

      case 'JUMD':

        this.srvOnBoarding.showOnBoarding = true;
        this.srvOnBoarding.getVerificationDocTypes().subscribe(res => {
          this.verificationDocTypes = res;
          this.srvOnBoarding.verificationDocTypes = res;
          this.showVerificationDocType = true;
        });

        break;

      case 'CNF':
        this.srvOnBoarding.showOnBoarding = true;
        this.showConfirmation = true;
        break;

      case 'SIGN':        
        this.srvOnBoarding.showOnBoarding = true;
        this.showConfirmation = true;
        this.signContract = true;
        break;
    }

    if (item.strType === 'F' && item.ScreenCode === 'UPL') {
      this.srvOnBoarding.strUploadType = item.ScreenCode;
      this.formUrl = 'Onboarding/UploadID';
      this.showUploadForm = true;
      this.formHeaderText = item.FormHeaderText;
    }

  }


  onCloseUploadPanel(event) {
    this.showUploadForm = false;
    event ? this.getBoardingStages() : this.srvOnBoarding.showOnBoarding = true;
  }

  btnClick(event, type) {
    switch (type) {
      case 'profile':
        this.menuItems = [];
        if (this.srvOnBoarding.loginInfo) {
          this.menuItems.push({ text: this.srvOnBoarding.loginInfo.name, disabled: true });
          this.menuItems.push({ text: this.srvOnBoarding.loginInfo.email, disabled: true });
        }
        this.menuItems.push({ text: 'Logout', disabled: false });
        this.showProfile = true;
        break;
      case 'menu':
        if (event.itemIndex === 2) {
          this.srvOnBoarding.isLoggedIn = false;
          this.atlAuthenticationService.logout();
        }
        break;
    }
  }

  onVerificationDocTypeConfirm(val) {
    this.showVerificationDocType = false;

    if (this.formType === 'JUMI') {
      this.srvOnBoarding.verifyID(val).subscribe(res => {
        window.open(res.toString(), '_self');
      });
    } else if (this.formType === 'JUMD') {
      this.srvOnBoarding.verifyDocument(val).subscribe(res => {
        window.open(res.toString(), '_self');
      });
    }

  }

  onScreenAction(event) {
    event ? this.getBoardingStages() : this.srvOnBoarding.showOnBoarding = true;
  }

  onConfirmProgressStage() {
    this.showConfirmation = false;
    if(this.signContract){
      this.srvOnBoarding.signContract().subscribe(res => {
        this.getBoardingStages();
      });
    } else {
      this.srvOnBoarding.progressThisStage().subscribe(res => {
        this.getBoardingStages();
      });
    }    
  }

}
