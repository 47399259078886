import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { authentication } from '../models/authentication';
import { User } from '../models/user';
import { SessionKey } from '../models/session-key';


@Injectable({
    providedIn: 'root'
})
export class AtlAuthenticationService {
    private currentUserSubject: BehaviorSubject<User>;
    private currentUser: Observable<User>;

    private currentAuthSubject: BehaviorSubject<authentication>;
    private currentAuth: Observable<authentication>;

    private currentSessionKeySubject: BehaviorSubject<SessionKey>;
    private currentSessionKey: Observable<SessionKey>;

    private common;

    public isValid = false;
    params: any;

    constructor(private httpClient: HttpClient) {
        this.common = {};

        if (localStorage.getItem('currentCommon') !== null) {
            this.common = JSON.parse(localStorage.getItem('currentCommon'));
            this.isValid = true;
        }

        this.currentUserSubject = new BehaviorSubject<User>(this.common.User);
        this.currentUser = this.currentUserSubject.asObservable();

        this.currentAuthSubject = new BehaviorSubject<authentication>(this.common.Authentication);
        this.currentAuth = this.currentAuthSubject.asObservable();

        this.currentSessionKeySubject = new BehaviorSubject<SessionKey>(this.common.SessionKey);
        this.currentSessionKey = this.currentSessionKeySubject.asObservable();
    }

    public get currentUserValue(): User {
        return this.currentUserSubject.value;
    }

    public get currentAuthValue(): authentication {
        return this.currentAuthSubject.value;
    }

    public get currentSessionKey$(): Observable<SessionKey> {
        return this.currentSessionKeySubject;
    }

    public get currentSessionKeyValue(): SessionKey {
        return this.currentSessionKeySubject.value;
    }

    public set setCurrentSessionKey(val) {
        this.currentSessionKeySubject.next(val);
    }

    validateExternalLogin(loginInfo, provider, token) {

        // return of({ // TODO: remove
        //     isValid: true,
        //     loginInfo
        // });

        const headers = new HttpHeaders();
        headers.append('Content-Type', 'application/x-www-form-urlencoded');

        const urlSearchParams = new URLSearchParams();
        urlSearchParams.set('grant_type', 'password');
        urlSearchParams.set('username', loginInfo.email);
        urlSearchParams.set('provider', provider);
        urlSearchParams.set('authToken', token);
        // urlSearchParams.set('username', username);
        // urlSearchParams.set('password', password);

        const body = urlSearchParams.toString();

        const apiUrl = environment.baseUrl + `token`;

        return this.httpClient.post<any>(apiUrl, body, {
            headers
        }).pipe(map(auth => {
            let isValid = false;
            // login successful if there's a jwt token in the response
            if (auth && auth.access_token) {
                isValid = true;

                const sessionKey = this.currentSessionKeySubject.value;
                this.common = {
                    Authentication: auth,
                    User: loginInfo,
                    SessionKey: sessionKey
                };

                this.currentUserSubject.next(loginInfo);
                this.currentAuthSubject.next(auth);
                // this.currentSessionKeySubject.next(sessionKey);

                // store user details and jwt token in local storage to keep user logged in between page refreshes
                localStorage.setItem('currentCommon', JSON.stringify(this.common));

            }

            return {
                isValid,
                loginInfo
            };
        }));
    }

    validateInternalLogin(loginInfo, formObj, provider) {

        const headers = new HttpHeaders();
        headers.append('Content-Type', 'application/x-www-form-urlencoded');

        const urlSearchParams = new URLSearchParams();
        urlSearchParams.set('grant_type', 'password');
        urlSearchParams.set('username', loginInfo.email);
        urlSearchParams.set('provider', provider);

        urlSearchParams.set('Surname', formObj.Surname);
        urlSearchParams.set('Mobile', formObj.Mobile);
        urlSearchParams.set('PostCode', formObj.PostCode);
        urlSearchParams.set('EmailID', formObj.EmailID);
        urlSearchParams.set('CompanyID', this.currentSessionKeySubject.value.CompanyID.toString());
        urlSearchParams.set('ContractorID', this.currentSessionKeySubject.value.ContractorID.toString());
        urlSearchParams.set('ProspectID', this.currentSessionKeySubject.value.ProspectID.toString());

        const body = urlSearchParams.toString();

        const apiUrl = environment.baseUrl + `token`;

        return this.httpClient.post<any>(apiUrl, body, {
            headers
        }).pipe(map(auth => {
            let isValid = false;
            // login successful if there's a jwt token in the response
            if (auth && auth.access_token) {
                isValid = true;

                const sessionKey = this.currentSessionKeySubject.value;
                this.common = {
                    Authentication: auth,
                    User: loginInfo,
                    SessionKey: sessionKey
                };

                this.currentUserSubject.next(loginInfo);
                this.currentAuthSubject.next(auth);
                // this.currentSessionKeySubject.next(sessionKey);

                // store user details and jwt token in local storage to keep user logged in between page refreshes
                localStorage.setItem('currentCommon', JSON.stringify(this.common));

            }

            return {
                isValid,
                loginInfo
            };
        }));
    }

    logout() {
        // remove user from local storage to log user out
        localStorage.removeItem('currentCommon');
        this.common = {};
        this.currentUserSubject.next(null);
    }

}
