
<div class="container">
    <div class="row pu-board-ui">
        <div class="col-sm-9 col-md-7 col-lg-5 pu-onboard">
            <div class="pu-onboarding">
                <dx-scroll-view>
                    <div *ngIf="srvOnBoarding.showOnBoarding">
                        <div id="onboarding-header" class="pu-onboarding-header d-flex pu-pxy-10">
                            <div class="flex-fill pu-mx-10 text-center">
                                <h2 >Onboarding Status</h2>                            
                                <progress-bar [progress]="compPercentage"
                                    [color-degraded]="{'0': '#afaead',  '25': '#e0b159', '45': '#ffa500', '75': '#cfe744', '90': '#4BB543'}">
                                </progress-bar>
                            </div>
                            <!-- <h2 class="flex-fill pu-mx-10 text-center">Onboarding Status</h2>                             -->
                            <dx-button id="profile" icon="user" stylingMode='text' class="user-profile"
                                (onClick)="btnClick($event, 'profile')"></dx-button>                               
                        </div>
                        <!-- <div id="progess-spinner">
                            <circle-progress [percent]="compPercentage" [maxPercent]="100" [radius]="100"
                                [innerStrokeWidth]="8" [showInnerStroke]='true' [outerStrokeColor]="'#78C000'"
                                [innerStrokeColor]="'#C7E596'" [animation]="true" [animationDuration]="300"
                                subtitle="Completed" subtitleFontSize="20">
                            </circle-progress>
                        </div> -->
                        <!-- <div id="progress-bar"> -->
                            <!-- <div class="text-center">PROGRESS</div> -->                            
                            <!-- <span>
                                <progress-bar [progress]="100" [color-degraded]="{'0': '#afaead',  '25': '#e0b159', '45': '#ffa500', '75': '#cfe744', '90': '#4BB543'}">
                                </progress-bar>
                            </span>                             -->
                        <!-- </div> -->
                        <div id="steps">
                            <div class="text-center pu-things-tocomplete">THINGS TO COMPLETE</div>

                            <!-- <mat-vertical-stepper linear='true' #stepper>
                                <div *ngFor="let item of onBoardingLevels">
                                    <mat-step [completed]="item.IsCompleted === '1'">
                                        <ng-template matStepLabel>
                                            <div [ngClass]="{'pending':item.IsCompleted === '0' && item.IsApproved === '0',
                                                                'awaitingApproval': item.IsCompleted === '1' && item.IsApproved === '0',
                                                                'completed':item.IsCompleted === '1' && item.IsApproved === '1'}">
                                                <span>{{item.StageDescription}}</span>
                            
                                                <dx-button *ngIf="item.IsCompleted === '0' && item.IsApproved === '0'" id="level-pending"
                                                    icon="far fa-hourglass" stylingMode='text' (click)="onRowItemClick(item)">
                                                </dx-button>
                                                <dx-button *ngIf="item.IsCompleted === '1' && item.IsApproved === '0'" id="level-awaiting"
                                                    icon="fas fa-clock" stylingMode='text'>
                                                </dx-button>                                               
                                                <dx-button *ngIf="item.IsCompleted === '1' && item.IsApproved === '1'" id="level-check"
                                                    icon="fas fa-check" stylingMode='text'>
                                                </dx-button>
                                            </div>
                                        </ng-template>
                                        <div class="pending">
                                            <p>{{item.StageNotes}}</p>
                                        </div>                                        
                                    </mat-step>
                                </div>
                            </mat-vertical-stepper> -->
                            <dx-list [dataSource]="onBoardingLevels" itemTemplate="listItem"
                                [focusStateEnabled]="false">
                                <div *dxTemplate="let item of 'listItem'" class="pu-dx-list">
                                    <span (click)="onRowItemClick(item)">
                                        <!-- [ngClass]="{'pending':item.IsCompleted === '0' && item.IsApproved === '0',
                                                                'awaitingApproval': item.IsCompleted === '1' && item.IsApproved === '0',
                                                                'completed':item.IsCompleted === '1' && item.IsApproved === '1'}" -->
                                        <div>
                                            <!-- Hiding tick mark since it will be covered now in the status color -->
                                            <!-- <dx-button [ngClass]="{'hideElem':item.IsCompleted === '0'}" 
                                            id="level-tick" icon="check" stylingMode='text'></dx-button> -->
                                            <span class="stage-desc"
                                                [ngClass]="{'pending':item.IsCompleted === '0' && item.IsApproved === '0',
                                                            'awaitingApproval': item.IsCompleted === '1' && item.IsApproved === '0',
                                                            'completed':item.IsCompleted === '1' && item.IsApproved === '1',
                                                            'enabled': item.IsEnabled === '1'}">{{item.StageDescription}}
                                            </span>
                                            <!-- Hiding right arrow for design improvement -->
                                            <!-- <dx-button [disabled]="item.IsEnabled === '0'" icon='chevronright' stylingMode='text'></dx-button> -->
                                            <dx-button *ngIf="item.IsCompleted === '0' && item.IsApproved === '0'"
                                                id="level-pending" icon="far fa-hourglass" stylingMode='text'
                                                (click)="onRowItemClick(item)" class="pending">
                                            </dx-button>

                                            <dx-button *ngIf="item.IsCompleted === '1' && item.IsApproved === '0'"
                                                id="level-awaiting" icon="fas fa-clock" stylingMode='text'
                                                class="awaitingApproval">
                                            </dx-button>
                                            <dx-button *ngIf="item.IsCompleted === '1' && item.IsApproved === '1'"
                                                id="level-check" icon="fas fa-check" stylingMode='text'
                                                class="completed">
                                            </dx-button>
                                            <div [ngClass]="{'pending':item.IsCompleted === '0' && item.IsApproved === '0',
                                            'awaitingApproval': item.IsCompleted === '1' && item.IsApproved === '0',
                                            'completed':item.IsCompleted === '1' && item.IsApproved === '1'}"
                                                class="pu-stage-notes">{{item.StageNotes}}</div>
                                        </div>
                                    </span>
                                </div>
                            </dx-list>
                        </div>
                    </div>
                    <div *ngIf="!srvOnBoarding.showOnBoarding">
                        <app-pu-upload-files *ngIf="formType === 'UPL'" [headerText]="formHeaderText"
                            (close)="onCloseUploadPanel($event)"></app-pu-upload-files>
                        <app-pu-update-utr *ngIf="formType === 'UTR'" (onScreenAction)="onScreenAction($event)">
                        </app-pu-update-utr>
                        <app-pu-update-p45 *ngIf="formType === 'P45'" (onScreenAction)="onScreenAction($event)">
                        </app-pu-update-p45>
                        <app-pu-update-bank *ngIf="formType === 'BNK'" (onScreenAction)="onScreenAction($event)">
                        </app-pu-update-bank>
                    </div>
                </dx-scroll-view>
            </div>
        </div>
    </div>
</div>

<app-pu-pop-up *ngIf="srvOnBoarding.isPopupVisible"></app-pu-pop-up>


<dx-context-menu *ngIf="showProfile" [items]="menuItems" target=".user-profile" showEvent="click" width="210px"
    [position]="{ my: 'top right', at: 'bottom center' }" cssClass="user-menu" (onItemClick)="btnClick($event, 'menu')">

</dx-context-menu>
<!-- 
  <dx-context-menu 
    cssClass="user-menu"
    *ngIf="showProfile"
    [dataSource]="menuItems" 
    [width]="200" 
    target=".user-profile" 
    showEvent="dxclick"
    [position]="{ my: 'top right', at: 'bottom center' }"
    [disabled]="true">
        <div *dxTemplate="let e of 'item'">
            <div>
                <span>{{e.text}}</span> 
            </div>
        </div>
</dx-context-menu> -->

<dx-popup class="dx-popup-class dx-popup-info" [width]="400" [height]="200" title="Document Type"
    [closeOnOutsideClick]="!showVerificationDocType" [(visible)]="showVerificationDocType">
    <div *dxTemplate="let data of 'content'">
        <dx-select-box #ddlVerificationDocType [dataSource]="verificationDocTypes" displayExpr="Name" valueExpr="ID">
        </dx-select-box>
        <br />
        <dx-button type="default" text="Confirm" (onClick)="onVerificationDocTypeConfirm(ddlVerificationDocType.value)"
            width="100%">
        </dx-button>
    </div>
</dx-popup>


<dx-popup class="dx-popup-class dx-popup-info" [width]="400" [height]="180" title="Confirm"
    [closeOnOutsideClick]="!showConfirmation" [(visible)]="showConfirmation">
    <div *dxTemplate="let data of 'content'">
        <div class="text-center">
            {{ signContract ? 'I acknowledge receipt of these terms and the conditions of employment and confirm my agreement  that these constitute my contract of employment with the company.' : 'Are you sure you want to Progress this Stage?'}}
            <br /><br />
            <span class="pr-4">
                <dx-button type="default" text="Yes" (onClick)="onConfirmProgressStage()" width="30%" class="">
                </dx-button>
            </span>
            <span>
                <dx-button text="No" (onClick)="showConfirmation = false; signContract = false" width="30%">
                </dx-button>
            </span>
        </div>
    </div>
</dx-popup>