import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { environment } from 'src/environments/environment';
import { AtlAuthenticationService } from '../common';
import { ILoginForm } from '../pu-login/pu.model';

@Injectable({
  providedIn: 'root'
})
export class PuOnboardingService {

  isLoggedIn = false;
  loginInfo: ILoginForm = {};
  CompanyID = 0;
  ContractorID = 0;
  curStageId = 0;
  isPopupVisible = false;
  popUpContent = 'Internal Error, Contact Support Team';
  strUploadType = 'UPL';
  sessionKeyNotAvail;


  headers: any = {
    // CompanyID: '17676',
    // ProspectID: '2',
    // ContractorID: '2',
  };
  pageHeader = '';
  showOnBoarding = false;
  isVerificationDocTypesCalled = false;
  verificationDocTypes = [];
  isVerificationIDTypesCalled = false;
  verificationIDTypes = [];

  constructor(
    private httpClient: HttpClient,
    private atlAuthenticationService: AtlAuthenticationService
  ) {
    this.atlAuthenticationService.currentSessionKey$.subscribe(res => {
      if (res) {
        this.sessionKeyNotAvail = false;
        this.CompanyID = res.CompanyID;
        this.ContractorID = res.ContractorID;
      } else {
        this.sessionKeyNotAvail = true;
      }
    });
  }

  getOnBoardingLevels(postData) {
    if(!this.sessionKeyNotAvail){
      return this.httpClient.get(environment.baseUrl + `Onboarding/List`);
    } else {
      this.showOnBoarding = false;
      this.isLoggedIn = false;
      return of(null);
    }
  }

  postSignIn(headers) {
    const apiUrl = environment.baseUrl + `Login/Signin`;
    return this.httpClient.get(apiUrl);
  }

  getSetBankDetails(postData = null) {
    const apiUrl = environment.baseUrl + `Onboarding/Bank`;
    this.headers.StageID = this.curStageId.toString();
    if (postData) {
      return this.httpClient.put(apiUrl, postData, { headers: this.headers });
    } else {
      return this.httpClient.get(apiUrl, { headers: this.headers });
    }
  }

  getSetP45Details(postData = null) {
    const apiUrl = environment.baseUrl + `Onboarding/P45Starter`;
    this.headers.StageID = this.curStageId.toString();
    if (postData) {
      return this.httpClient.put(apiUrl, postData, { headers: this.headers });
    } else {
      return this.httpClient.get(apiUrl, { headers: this.headers });
    }
  }

  getUTRDetails() {
    const apiUrl = environment.baseUrl + `Onboarding/UTR`;
    this.headers.StageID = this.curStageId.toString();
    return this.httpClient.get(apiUrl, { headers: this.headers });
  }

  setUTRDetails(utrValue = '') {
    const apiUrl = environment.baseUrl + `Onboarding/UpdateUTR/` + utrValue + '/';
    this.headers.StageID = this.curStageId.toString();
    return this.httpClient.put(apiUrl, null, { headers: this.headers });
  }

  getSetUploadDetails(postData = null) {
    const apiUrl = environment.baseUrl + `Onboarding/UploadDocument/` + this.strUploadType + '/';
    this.headers.StageID = this.curStageId.toString();
    if (postData) {
      return this.httpClient.post(apiUrl, postData, { headers: this.headers });
    } else {
      return this.httpClient.get(apiUrl, { headers: this.headers });
    }
  }

  verifyID(type) {
    const apiUrl = environment.baseUrl + `Onboarding/VerifyID/` + type.toString();
    this.headers.StageID = this.curStageId.toString();
    return this.httpClient.put(apiUrl, null, { headers: this.headers });
  }

  verifyDocument(type) {
    const apiUrl = environment.baseUrl + `Onboarding/VerifyDocument/` + type.toString();
    this.headers.StageID = this.curStageId.toString();
    return this.httpClient.put(apiUrl, null, { headers: this.headers });
  }

  updateJumioSuccess(ReferenceKey) {
    const apiUrl = environment.baseUrl + `Onboarding/UpdateVerifyReferenece/` + ReferenceKey;
    this.headers.StageID = this.curStageId.toString();
    return this.httpClient.put(apiUrl, null, { headers: this.headers });
  }

  getVerificationIDTypes(): Observable<any[]> {

    if (this.verificationIDTypes && this.verificationIDTypes.length > 0) {
      return of(this.verificationIDTypes);
    } else {
      const apiUrl = environment.baseUrl + `Onboarding/VerifyIDType`;
      return this.httpClient.get<any[]>(apiUrl, { headers: this.headers });
    }

  }

  getVerificationDocTypes(): Observable<any[]> {

    if (this.verificationDocTypes && this.verificationDocTypes.length > 0) {
      return of(this.verificationDocTypes);
    } else {
      const apiUrl = environment.baseUrl + `Onboarding/VerifyDocumentType`;
      return this.httpClient.get<any[]>(apiUrl, { headers: this.headers });
    }

  }

  progressThisStage() {

    const apiUrl = environment.baseUrl + `Onboarding/ProgressThisStage`;
    this.headers.StageID = this.curStageId.toString();
    return this.httpClient.put(apiUrl, null, { headers: this.headers });
  }


  signContract() {
    const apiUrl = environment.baseUrl + `Onboarding/SignContract`;
    this.headers.StageID = this.curStageId.toString();
    this.headers.IsCompleted = "true";
    return this.httpClient.put(apiUrl, null, { headers: this.headers });
  }
}
