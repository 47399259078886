<div class="pu-container"> 
  <!-- <router-outlet></router-outlet> -->
  <!-- <app-pu-login *ngIf="!puServ.isLoggedIn else onBoard"></app-pu-login> -->
  <app-pu-login-one *ngIf="!puServ.isLoggedIn else onBoard"></app-pu-login-one>
  <!-- <app-pu-login-two *ngIf="!puServ.isLoggedIn else onBoard"></app-pu-login-two> -->
  <ng-template #onBoard>
    <app-pu-onboarding></app-pu-onboarding>
  </ng-template> 
</div>
<dx-load-panel
    [closeOnOutsideClick]="false"
    [(visible)]="isHttpActive">
</dx-load-panel>