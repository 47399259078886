import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import notify from 'devextreme/ui/notify';
import { pageMsg, standards } from '../constants';
import { PuOnboardingService } from '../pu-services/pu-onboarding.service';

@Component({
  selector: 'app-pu-upload-files',
  templateUrl: './pu-upload-files.component.html',
  styleUrls: ['./pu-upload-files.component.scss']
})
export class PUUploadFilesComponent implements OnInit {

  @Input() headerText: string = '';
  @Output() close: EventEmitter<any> = new EventEmitter();

  fileData: File[] = [];
  previewUrl: any[] = [];
  fileUploadProgress: string = null;
  apiIp = [];
  allowedFileFormats = ['.jpg', '.jpeg','.gif','.png','.pdf'];

  constructor(
    public srvOnBoarding: PuOnboardingService
  ) { }

  ngOnInit(): void {
  }

  getUploadedFiles(fileInput: any) {
    if(!this.validateFiles(fileInput)){
      return;
    }
    this.fileData[this.fileData.length] = <File>fileInput.target.files[0];
    this.preview();
  }

  private validateFiles(fileInput){
    if(fileInput && fileInput.target && fileInput.target.files && fileInput.target.files.length){
      const file = fileInput.target.files[0];
      const fileType = file.type.includes('/') ?  file.type.split('/')[1] : file.type;
      if (fileType.match('/' + standards.allowedFileTypes + '/g') === null) {
        this.srvOnBoarding.isPopupVisible = true;
        this.srvOnBoarding.popUpContent = pageMsg.valUploadedFileType;
        return false;
      } else {
        return true;
      }
    }
    return false;
  }

  preview() {
    this.fileData.forEach((fileData, index) => {
      // Show preview 
      const mimeType = fileData.type.includes('/') ? fileData.type.split('/')[1] : fileData.type;
      var reader = new FileReader();
      reader.readAsDataURL(fileData);
      // reader.readAsArrayBuffer(fileData);
      reader.onload = (_event) => {
        this.previewUrl[index] = reader.result;
        this.apiIp.push({
          fileName: fileData.name,
          image: reader.result.toString().includes(',') ? reader.result.toString().split(',')[1] : reader.result,
          extension: mimeType
        })
      }
    });
  }

  onSave() {
    if(this.apiIp && this.apiIp.length <= 0){
      this.srvOnBoarding.isPopupVisible = true;
      this.srvOnBoarding.popUpContent = pageMsg.valUploadFiles;
      return;
    }
    this.srvOnBoarding.getSetUploadDetails(this.apiIp[0]).subscribe(resp =>{
      if (resp) {
        notify({
          message: resp,
          type: 'success',
          displayTime: 1000,
          width: 350,
          position: {
            my: 'right bottom',
            at: 'right bottom',
            offset: "-20 -25"
          }
        });
      }  
      this.close.emit(true);
    });
    
    // const formData = new FormData();
    // formData.append('files', this.fileData);
     
    // this.fileUploadProgress = '0%';
 
    // this.http.post('https://us-central1-tutorial-e6ea7.cloudfunctions.net/fileUpload', formData, {
    //   reportProgress: true,
    //   observe: 'events'   
    // })
    // .subscribe(events => {
    //   if(events.type === HttpEventType.UploadProgress) {
    //     this.fileUploadProgress = Math.round(events.loaded / events.total * 100) + '%';
    //     console.log(this.fileUploadProgress);
    //   } else if(events.type === HttpEventType.Response) {
    //     this.fileUploadProgress = '';
    //     console.log(events.body);          
    //     alert('SUCCESS !!');
    //   }
         
    // }) 
  }
}
