import { DxButtonModule, DxContextMenuModule, DxFormModule, DxListModule, DxNumberBoxModule, DxPopupModule, DxRadioGroupModule, DxScrollViewModule, DxSelectBoxModule, DxTextBoxModule, DxValidationGroupModule, DxValidatorModule } from 'devextreme-angular';
import { DxiItemModule, DxiValidationRuleModule } from 'devextreme-angular/ui/nested';

export const DXCompModules = [
    DxTextBoxModule,
    DxNumberBoxModule,
    DxButtonModule,
    DxScrollViewModule,
    DxValidationGroupModule,
    DxiValidationRuleModule,
    DxValidatorModule,
    DxRadioGroupModule,
    DxListModule,
    DxiItemModule,
    DxContextMenuModule,
    DxSelectBoxModule,
    DxFormModule,
    DxPopupModule,
]