export interface ILoginForm {
    name?: string;
    mobile?: string;
    company?: string;
    email?: string;
    postalCode?: string;
    imgProfile?: string;
}

//#region Enums

export enum LoginTypes {
    Google = 'G',
    FaceBook = 'F',
    Others = 'O', 
    Form = 'M',
    Back = 'B',
}
//#endregion