import { Component, OnInit } from '@angular/core';
import { FacebookLoginProvider, GoogleLoginProvider, SocialAuthService } from 'angularx-social-login';
import { pageMsg } from '../constants';
import { ILoginForm, LoginTypes } from '../pu-login/pu.model';
import { PuOnboardingService } from '../pu-services/pu-onboarding.service';


@Component({
  selector: 'app-pu-login-two',
  templateUrl: './pu-login-two.component.html',
  styleUrls: ['./pu-login-two.component.scss']
})
export class PuLoginTwoComponent implements OnInit {
  title = 'Log in';
  radioGroup = ['Email', 'Postal Code'];
  radioValue = this.radioGroup[0];
  dynamicPlaceholder = 'Email'
  dynValue;
  dynValMsg = pageMsg.reqEmail;
  minMob=10;
  maxMob=13;
  formObj: ILoginForm = {};
  showErrMsg = false;

  constructor(
    private socialAuthServ: SocialAuthService,
    private puServ: PuOnboardingService,
  ) { }

  ngOnInit(): void {
  }

  btnClick(event, btnType) {
    switch (btnType) {
      case LoginTypes.Google:
        this.socialAuthServ.signIn(GoogleLoginProvider.PROVIDER_ID).then(value => {
          console.log('GSignin', value);
          if (value) {
            this.puServ.isLoggedIn = true;
            this.puServ.loginInfo.name = value.name;
            this.puServ.loginInfo.email = value.email;
            this.puServ.loginInfo.imgProfile = value.photoUrl;
          }
        });
        break;
      case LoginTypes.FaceBook:
        this.socialAuthServ.signIn(FacebookLoginProvider.PROVIDER_ID).then(value => {
          console.log('FBSignin', value);
          if (value) {
            this.puServ.isLoggedIn = true;
            this.puServ.loginInfo.name = value.name;
            this.puServ.loginInfo.email = value.email;
            this.puServ.loginInfo.imgProfile = value.photoUrl;
          }
        });
        break;
        case LoginTypes.Others:
          if (!event.validationGroup.validate().isValid) {
            return;
          } else {
            if(!this.formObj.email || !this.formObj.postalCode){
              this.showErrMsg = true;
              return;
            } else {
              this.showErrMsg = false;
            }
            this.puServ.isLoggedIn = true;
          }          
          break;
    }
  }
  
  onRGValueChanged(event) {
    this.dynamicPlaceholder = event.value;
    if (event.value === this.radioGroup[0]) {
      this.dynValMsg= pageMsg.reqEmail;
      this.formObj.email = this.dynValue;
      this.formObj.postalCode = null;
    } else {
      this.dynValMsg= pageMsg.reqPostal;
      this.formObj.email = null;
      this.formObj.postalCode = this.dynValue;
    }
  }
}
