import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import notify from 'devextreme/ui/notify';
import { PuOnboardingService } from 'src/app/pu-services/pu-onboarding.service';
import { IBankObj } from '../boarding.model';

@Component({
  selector: 'app-pu-update-bank',
  templateUrl: './pu-update-bank.component.html',
  styleUrls: ['./pu-update-bank.component.scss']
})

export class PuUpdateBankComponent implements OnInit {

  @Output() onScreenAction : EventEmitter<any> = new EventEmitter();
  isEditable = true;
  formObj:IBankObj = {};

  constructor(
    public srvOnBoarding: PuOnboardingService
  ) { }

  ngOnInit(): void {
    this.srvOnBoarding.getSetBankDetails(null).subscribe(resp => {
      console.log('Bank', resp);
    });
  }


  onSave(event) {
    if (!event.validationGroup.validate().isValid) {
      return;
    } else {
      const postData = {
        Title: "Mr.",
        Forename: "XXX",
        Surname: "YYY",
        AccountName: this.formObj.accName, 
        AccountNumber: this.formObj.accNo,
        SortCode: this.formObj.sortCode,
        BankName: this.formObj.bankName,
        BuildingSocietyNumber: this.formObj.socNo
      };
      this.srvOnBoarding.getSetBankDetails(postData).subscribe((respBnk) => {
        if(respBnk){
          notify({
              message: respBnk, 
              type: 'success', 
              displayTime: 1000,
              width: 350,
              position: {
                  my: 'right bottom',
                  at: 'right bottom',
                  offset:"-20 -25"
              }
          });
      }  
        this.onScreenAction.emit(true);
        // this.router.navigate(['/onBoarding']);
      });
    }
  }
  
  backToList() {
    this.onScreenAction.emit(false);
  }
}
