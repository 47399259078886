export const pid = {
    Google: '469670505406-qhocan6ftiv16cujc4ioacssg7ppgaqs.apps.googleusercontent.com', /// durai
    // '466462361654-q1ujv173rf7362dbcteem4f01o906gph.apps.googleusercontent.com', // aashiq
    // '1097865340549-r6h87p2b5d70aua24jrc3ne7d3g0s0ce.apps.googleusercontent.com', // Vijay
    Facebook: '896745681203148', // '2507052752932423', // '2730285477224355'
}

export const standards = {
    allowedFileTypes: 'jpg|jpeg|gif|png|pdf',
}
export const pageMsg = {
    reqEmail: 'Email is required',
    reqPostal: 'Postal Code is required',
    valUploadFiles: 'Please upload the file',
    valUploadedFileType: "The uploaded file's content type is not allowed",
}