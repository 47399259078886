import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AtlAuthenticationService, SessionKey } from './common';
import { AtlSecurityService } from './common/core/atl-security.service';
import { HTTPStatus } from './common/core/httpinterceptor.service';
import { PuOnboardingService } from './pu-services/pu-onboarding.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'Onboarding-Angular-UI';
  isHttpActive: boolean;
  isSessionActive: boolean;

  constructor(
    public puServ: PuOnboardingService,
    private actRoute: ActivatedRoute,
    private httpStatus: HTTPStatus,
    private atlAuthenticationService: AtlAuthenticationService,
    private router: Router,
    private atlSecurityService: AtlSecurityService,
  ) {

    // if (router.url.indexOf('verify') >= 0) {
    //   this.puServ.isLoggedIn = false;
    // }

    this.actRoute.queryParams.subscribe(params => {
      this.atlAuthenticationService.params = this.router.url;
      if (params.id) {
        const decryptText = this.atlSecurityService.decrypt(params.id);

        const sess: SessionKey = {
          CompanyID: Number(decryptText.split('|')[0]),
          ProspectID: Number(decryptText.split('|')[1]),
          ContractorID: Number(decryptText.split('|')[2]),
        };
        
        this.redirectIfSessionisAvail(sess);

        if (params.screencode && params.verify) {

          switch (params.verify) {
            case 'success':

              const customerInternalReference = params.customerInternalReference;
              const transactionReference = params.transactionReference;
              this.puServ.curStageId = params.screencode;

              this.puServ.updateJumioSuccess(transactionReference).subscribe(res => {
                // this.puServ.isLoggedIn = true;
                window.open('/?id=' + params.id, '_self');
              });

              break;

            case 'failure':
              alert('JUMIO verification failed. Please try again');
              break;

            case 'callback':
              break;
          }

        }

      }
    });

    this.httpStatus.getHttpStatus().subscribe((status: boolean) => { this.isHttpActive = status; });
  }

  private redirectIfSessionisAvail(sess: SessionKey) {
    if (localStorage.getItem('currentCommon') !== null) {
      const keyFromSession = JSON.parse((localStorage.getItem('currentCommon')));
      if (keyFromSession) {
        this.isSessionActive = true;
        for (const propName in keyFromSession.SessionKey) {
          if (Object.prototype.hasOwnProperty.call(keyFromSession.SessionKey, propName) &&
            keyFromSession.SessionKey[propName] !== sess[propName]) {
            this.isSessionActive = false;
          }
        }
      }
    }
    if (this.isSessionActive) {
      this.puServ.isLoggedIn = true;
    } else {
      this.puServ.isLoggedIn = false;
      this.atlAuthenticationService.setCurrentSessionKey = sess;
    }
  }
}
