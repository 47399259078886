<div class="container pu-upload d-flex flex-column">
    <div class="d-flex title-container pu-upload-files">
        <dx-button icon="arrowleft" stylingMode='text' (onClick)="backToList();">
        </dx-button>
        <span class="fw-bold">{{srvOnBoarding.pageHeader}}</span>
        <!-- <dx-button icon="edit" stylingMode='text' class="eip-mt-3 ml-auto" (onClick)="isEditable=true;">
        </dx-button> -->
    </div>
    <dx-validation-group>
        <div class="pu-mxy-15 pu-upload-doc flex-fill">
    
            <div class="dx-field">
                <dx-text-box [(value)]="formObj.accName" placeholder="Bank Account Name" width="100%"
                    [disabled]="!isEditable">
                    <dx-validator>
                        <dxi-validation-rule type="required" message="Bank Account Name is required">
                        </dxi-validation-rule>
                    </dx-validator>
                </dx-text-box>
            </div>
            <div class="dx-field">
                <dx-number-box [(value)]="formObj.accNo" placeholder="Account Number" width="100%" [disabled]="!isEditable">
    
                    <!-- <dx-text-box [(value)]="formObj.accNo" placeholder="Account Number" width="100%" [disabled]="!isEditable"> -->
                    <dx-validator>
                        <dxi-validation-rule type="required" message="Account Number is required"></dxi-validation-rule>
                        <dxi-validation-rule type="range" min='0' max='99999999' message="Account Number cannot be more than 8 characters"></dxi-validation-rule>
                    </dx-validator>
                    <!-- </dx-text-box> -->
                </dx-number-box>
            </div>
            <div class="dx-field">
                <dx-number-box [(value)]="formObj.sortCode" placeholder="Sort Code" width="100%" [disabled]="!isEditable">
                    <!-- <dx-text-box [(value)]="formObj.sortCode" placeholder="Sort Code" width="100%" [disabled]="!isEditable"> -->
                    <dx-validator>
                        <dxi-validation-rule type="required" message="Sort Code is required"></dxi-validation-rule>
                        <dxi-validation-rule type="range" min='0' max='999999' message="Sort Code cannot be more than 6 characters"></dxi-validation-rule>
                    </dx-validator>
                    <!-- </dx-text-box> -->
                </dx-number-box>
            </div>
            <div class="dx-field">
                <dx-text-box [(value)]="formObj.bankName" placeholder="Bank Name" width="100%" [disabled]="!isEditable">
                    <dx-validator>
                        <dxi-validation-rule type="required" message="Bank Name is required"></dxi-validation-rule>
                    </dx-validator>
                </dx-text-box>
            </div>
            <div class="dx-field">
                <dx-text-box [(value)]="formObj.socNo" placeholder="Building Society Number" width="100%"
                    [disabled]="!isEditable">
                    <!-- <dx-validator>
                        <dxi-validation-rule type="required" message="Building Society Number is required">
                        </dxi-validation-rule>
                    </dx-validator> -->
                </dx-text-box>
            </div>
    
        </div>
        <div>
            <dx-button class="btn btn-save w-100" (onClick)="onSave($event)">Save</dx-button>
        </div>
    </dx-validation-group>
</div>