import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import notify from 'devextreme/ui/notify';
import { PuOnboardingService } from 'src/app/pu-services/pu-onboarding.service';

@Component({
  selector: 'app-pu-update-p45',
  templateUrl: './pu-update-p45.component.html',
  styleUrls: ['./pu-update-p45.component.scss']
})
export class PuUpdateP45Component implements OnInit {

  @Output() onScreenAction : EventEmitter<any> = new EventEmitter();
  
  lstDeclarationType = [
      {
        text: 'A - This is the first Job since the start of this tax year',
        value: 'A'
      },
      {
        text: 'B - This is currently the only job',
        value: 'B'
      },
      {
        text: 'C - Have another Job or Pension',
        value: 'C'
      },
      {
        text: 'Not Applicable',
        value: ''
      },
    ];
  formObj:any = {
    grossTax : 0,
    taxDeducted: 0,
  };

  constructor(
    public srvOnBoarding: PuOnboardingService
  ) { }

  ngOnInit(): void {
    
    this.srvOnBoarding.getSetP45Details(null).subscribe(resp => {
    });
  }

  valueChange(event, key) {
    this.formObj[key] = event ? event.value : 0;
  }

  onSave(event){
    if (!event.validationGroup.validate().isValid) {
      return;
    } else {    
      const postData = {
        StarterType: this.formObj.decType,
        P45GrossforTax: this.formObj.grossTax,
        P45TaxDeducted: this.formObj.taxDeducted
      };
      this.srvOnBoarding.getSetP45Details(postData).subscribe(respP45 => {
        if (respP45) {
          notify({
            message: respP45,
            type: 'success',
            displayTime: 1000,
            width: 350,
            position: {
              my: 'right bottom',
              at: 'right bottom',
              offset: "-20 -25"
            }
          });
        }  
        this.onScreenAction.emit(true);
        // this.router.navigate(['/onBoarding']);
      });
    }
  }
 
  backToList() {
    this.onScreenAction.emit(false);
  }
}
